import styled from "styled-components";

export const MainLayout = styled.div`
  padding: 5rem;
  @media screen and (max-width: 642px) {
    padding: 4rem;
  }
  @media screen and (max-width: 571px) {
    padding: 0.4rem;
  }
`;

export const InnerLayout = styled.div`
  padding: 5rem 0;
  @media (max-width: 430px) {
    padding: 1rem 0;
  }
`;
